import { Component, Inject, OnInit,TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-annotation-popup',
  templateUrl: './annotation-popup.component.html',
  styleUrls: ['./annotation-popup.component.scss']
})
export class AnnotationPopupComponent implements OnInit {
  array: any = [];
  damage_picture = '';
  name = '';
  damage_image1 = null;
  damage_image2 = null;
  damage_image3 = null;
  damage_image4 = null;
  table: any = [];
  array_list: any=[];
  popup_img: any;
  Label_Name: any;
  modalRef: BsModalRef;
  constructor(private dialog: MatDialog,
    private modalService: BsModalService,
    public dialogRef: MatDialogRef<AnnotationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public CommonService: CommonService,) {
     }
  ngOnInit(): void {

    this.array_list = this.data.array;
    this.name = this.array_list.body_part;
    this.array = JSON.parse(this.array_list.arr);
    this.damage_picture = this.array_list.damage_file || null;
    this.damage_image1 = this.array_list.damage_image1 || null;
    this.damage_image2 = this.array_list.damage_image2 || null;
    this.damage_image3 = this.array_list.damage_image3 || null;
    this.damage_image4 = this.array_list.damage_image4 || null;

  }
  close() {
    this.dialogRef.close();
  }

    // ========================================
  
    zoomin1() {
      var myImg = document.getElementById("zmap1");
      var currWidth = myImg.clientWidth;
      if (currWidth == 2500) return false;
      else {
        myImg.style.width = currWidth + 100 + "px";
      }
    }
  
    zoomout1() {
      var myImg = document.getElementById("zmap1");
      var currWidth = myImg.clientWidth;
      if (currWidth == 100) return false;
      else {
        myImg.style.width = currWidth - 100 + "px";
      }
    }
  
    OnClick_imgPopup(Img_Popup: TemplateRef<any>, data: any, label_name: any) {
      this.popup_img = data.split('@@@')[0];
      this.Label_Name = label_name;
      this.modalRef = this.modalService.show(Img_Popup, Object.assign({}, { class: "modal-lg" }));
    }
}