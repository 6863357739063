<head>
    <script src="https://unpkg.com/markerjs2/markerjs2.js"></script>
    <script>
        function showMarkerArea(target) {
            const markerArea = new markerjs2.MarkerArea(target);
            markerArea.addEventListener("render", (event) => (target.src = event.dataUrl));
            markerArea.show();
        }
    </script>
</head>
<div style="padding: 10px 1rem !important;">
    <h2 class=" pull-left">{{name}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<hr style=" margin-top: 2rem;">
<div class="row">
    <div class="col-md-12" style="text-align:center ;">
        <div>
            <a href="javascript:;" *ngIf="!array_list ">
                <img class="card-img-top"
                    [src]="'https://devkliteapi.symplfy.in/uploadimage/' + array_list.damage_file.split('@@@')[0]"
                    alt="Bebinca" /></a>

            <a href="javascript:;" *ngIf="array_list.arr">
                <iframe id="zmap"
                    [src]="'assets/view.html?'+'https://devkliteapi.symplfy.in/uploadimage/' + array_list.damage_file.split('@@@')[0] +'&&'+ array_list.arr | safe : 'resourceUrl'"
                    frameborder="0" style="width: 100% !important; height: 300px !important;"></iframe>
            </a>
        </div>
    </div>
    <div class="col-md-12  mt-3" style="padding:10px 1rem !important">
        <table>
            <tr
            style="background-color: #1c3e72;color: #ffffff;">
            <th>Body Part</th>
            <th>Damage Type</th>
            <th>Severity</th>
            <th>Replace-Repair</th>
            <th>Internal Damage</th>
        </tr>
        <tr>
            <td>{{array_list.body_part}}</td>
            <td>{{array_list.damage_serverity}}</td>
            <td>{{array_list.damage_type}}</td>
            <td>{{array_list.damage_property}}</td>
            <td>{{array_list.internal_damage}}</td>
        </tr>
        </table>
    </div>
</div>
<div class="row mt-3" style="padding: 10px 1rem !important;"
    *ngIf="damage_image1 || damage_image2 || damage_image3 || damage_image4">
    <h2 class=" pull-left">Damage Images :</h2>
    <hr style=" margin-top: 2rem;" class="mt-0">
    <div class="col-12" style="text-align:center ;">
        <div class="col-md-3 mt-0" style="display: inline-block">
            <a href="javascript:;" *ngIf="damage_image1">
                <img class="card-img-top" (click)="OnClick_imgPopup(Image_popup,damage_image1,damage_image1.label)"
                    [src]="'https://devkliteapi.symplfy.in/uploadimage/' + damage_image1.split('@@@')[0]"
                    alt="Bebinca" /></a>
        </div>
        <div class="col-md-3 mt-0" style="display: inline-block">
            <a href="javascript:;" *ngIf="damage_image2">
                <img class="card-img-top" (click)="OnClick_imgPopup(Image_popup,damage_image2,damage_image2.label)"
                    [src]="'https://devkliteapi.symplfy.in/uploadimage/' + damage_image2.split('@@@')[0]"
                    alt="Bebinca" /></a>
        </div>
        <div class="col-md-3 mt-0" style="display: inline-block">
            <a href="javascript:;" *ngIf="damage_image3">
                <img class="card-img-top" (click)="OnClick_imgPopup(Image_popup,damage_image3,damage_image3.label)"
                    [src]="'https://devkliteapi.symplfy.in/uploadimage/' + damage_image3.split('@@@')[0]"
                    alt="Bebinca" /></a>
        </div>
        <div class="col-md-3 mt-0" style="display: inline-block">
            <a href="javascript:;" *ngIf="damage_image4">
                <img class="card-img-top" (click)="OnClick_imgPopup(Image_popup,damage_image4,damage_image4.label)"
                    [src]="'https://devkliteapi.symplfy.in/uploadimage/' + damage_image4.split('@@@')[0]"
                    alt="Bebinca" /></a>
        </div>
    </div>
</div>

<!-- ============================================= -->

<!-- ================================================================================================== -->
<ng-template #Image_popup class="pb-5">
    <div class="modal-header">
        <h4 class="modal-title pull-left mb-0">{{Label_Name}}</h4>
        <button type="button" class="close pull-right mt-1 mr-0" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <section class="mt-1">
            <div class="row text-center">
                <div id="znavbar">
                    <button class="zoombtn" type="button" (click)="zoomin1()"> Zoom In</button>
                    <button class="zoombtn" type="button" (click)="zoomout1()"> Zoom Out</button>
                </div>
                <div class="zmain dragscroll">
                    <img id="zmap1" [src]="'https://devkliteapi.symplfy.in/uploadimage/' + popup_img" />
                    <div id="clothe-tshirt-maker"></div>
                </div>
            </div>
        </section>
    </div>
</ng-template>
