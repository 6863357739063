import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import * as markerjs2 from 'markerjs2';
import { CommonService } from 'src/app/services/common.service';
import { Dropdownlist } from 'src/app/services/dropdown.service';
@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss'],
})
export class AnnotationComponent implements OnInit {
  image: any;
  popup_img: any;
  dynamicForm: FormGroup;
  d: any;
  paint_type1: any = [];
  submitted: boolean;
  data_list: any;
  damange_name: any;
  get f() {
    return this.dynamicForm.controls;
  }
  get t() {
    return this.f.annitation_report as FormArray;
  }
  body_partlist: any = [];
  damage_type2: any = [];
  damage_Severity_type1: any = [];
  paint_type1_hide:boolean



  paint_hide: boolean = false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AnnotationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data,
    public CommonService: CommonService,
    private Formbuilder: FormBuilder,
    public dropdwonlist: Dropdownlist
  ) {
    window.localStorage.removeItem('annotation_html');
    let name = this.data['img'].split('@@@')[0];
  }

  ngOnInit(): void {

    this.dropdwonlist.body_part_List.sort(function (a, b) {
      return a.bodylist_Name < b.bodylist_Name ? -1 : a.bodylist_Name > b.bodylist_Name ? 1 : 0;
    });

    window.localStorage.removeItem('annotation_html');
    this.CommonService.loadRAutocomplete(
      'https://unpkg.com/markerjs2/markerjs2.js'
    );
    let name = this.data['img'].split('@@@')[0];
    this.popup_img = 'https://devkliteapi.symplfy.in/uploadimage/' + name;
    this.dynamicForm = this.Formbuilder.group({
      annitation_report: new FormArray([]),
    });
    // ------------------------------------------
    this.body_partlist = this.dropdwonlist.body_part_List.map((s: any) => ({
      id: s.bodylist_Name,
      Usertype: s.bodylist_Name,
    }));
    // ------------------------------------------
    this.damage_Severity_type1 = this.dropdwonlist.damage_Severity_type.map((s: any) => ({
      id: s.Severity_Name,
      Usertype: s.Severity_Name,
    }));

    this.damage_type2 = this.dropdwonlist.damage_type1.map((s: any) => ({
      id: s.jobtype_Name,
      Usertype: s.jobtype_Name,
    }));


    // ------------------------------------------------------------------
    this.tickets();

  }

  tickets() {
    this.t.push(
      this.Formbuilder.group({
        body_part: [null],
        damage_type: [null],
        damage_serverity: [null],
        damage_property: [null],
        paint: [null],
        // internal_damage: 'No',
      })
    );



  }

  showMarkerArea(target) {
    const markerArea = new markerjs2.MarkerArea(target);
    markerArea.addEventListener(
      'render',
      (event) => (target.src = event.dataUrl)
    );
    markerArea.show();
  }

  OnSubmit_list() {
    this.submitted = true;
    const frameContent = document.getElementById('zmap');
    const iWindow = (<HTMLIFrameElement>frameContent).contentWindow;
    const iDocument = iWindow.document;
    const element = iDocument.getElementById('was');
    let data1 = JSON.parse(localStorage.getItem('annotation_html'));

    // this.dialogRef.close(data);

    if (data1 !== null) {
      if (data1[0].markers.length === this.t.length) {
        let i = 1;
        this.t.value.map((s: any) => {
          if (
            s.body_part !== null &&
            // s.damage_property !== null &&
            s.damage_serverity !== null &&
            s.damage_type !== null
            // s.internal_damage !== null
          ) {
            if (this.t.value.length === i) {

              this.data_list = { data: data1, obj: this.t.value };
              this.dialogRef.close(this.data_list);
            }
          } else {
            this.CommonService.SwalError('Please Select All fields', 'Error');
          }
          i = i + 1;
        });
      } else {
        this.CommonService.SwalError(
          'Please Annotation & Part Details',
          'Error'
        );
      }
    } else {
      this.CommonService.SwalError('Please Select Annotation Part', 'Error');
    }
  }

  close() {
    this.dialogRef.close([]);
  }
  deleteRow(index: number) {
    this.t.removeAt(index);
  }

  Onselect(data: any) {
    if (data == "Bumper Front" || data == "Bumper Rear" || data === "Hood" || data === "Fender" || data === "Door" || data === "Tail Door" || data === "Roof Panel" || data === "Wheel Arch Spoiler" || data === "Quarter Panel (Rear)") {
      this.paint_type1 = this.dropdwonlist.paint_type.map((s: any) => ({
            id: s.value,
            Usertype: s.Severity_Name,
          }));
          this.paint_type1_hide=false;
    }else{
      this.damange_name=data
      this.paint_type1_hide=true;
      this.paint_type1 =[]
    }

  }

}
