import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Dropdownlist {

  damage_type1: any = [
    { jobtype_Name: 'Broken' },
    { jobtype_Name: 'Crack' },
    { jobtype_Name: 'Dent' },
    { jobtype_Name: 'Scratch' },
    { jobtype_Name: 'Tear' },
  ];

  body_part_List = [
    { bodylist_Name: 'Bumper Front' },
    { bodylist_Name: 'Bumper Rear' },
    { bodylist_Name: 'Bumper End' },
    { bodylist_Name: 'Valance Panel (Bumper Spoiler)' },
    { bodylist_Name: 'Fender Extension Panel (Bumper Bracket)' },
    { bodylist_Name: 'Head Lamp' },
    { bodylist_Name: 'Fog Lamp' },
    { bodylist_Name: 'Show Grill' },
    { bodylist_Name: 'Bumper Reflector' },
    { bodylist_Name: 'Bumper - Parking Aid Sensors' },
    { bodylist_Name: 'Monogram' },
    { bodylist_Name: 'Signal Lamp' },
    { bodylist_Name: 'Number Plate Garnish' },
    { bodylist_Name: 'Engine Bottom Shield' },
    { bodylist_Name: 'Hood' },
    { bodylist_Name: 'Header Panel (Front Of Radiator Panel)' },
    { bodylist_Name: 'Cowl Panel' },
    { bodylist_Name: 'Wind Shield Front' },
    { bodylist_Name: 'Fender' },
    { bodylist_Name: 'Inner Fender (Fender Liner)' },
    { bodylist_Name: 'Fender Flare' },
    { bodylist_Name: 'Side View Mirror' },
    { bodylist_Name: 'Side View Mirror Light' },
    { bodylist_Name: 'Wheel Arch Panel' },
    { bodylist_Name: 'Wheel Arch Spoiler' },
    { bodylist_Name: 'Wheel Rim' },
    { bodylist_Name: 'Wheel Cap' },
    { bodylist_Name: 'Tyre' },
    { bodylist_Name: 'Mud Flap' },
    { bodylist_Name: 'Door' },
    { bodylist_Name: 'Door Skin' },
    { bodylist_Name: 'Door Protector/Moulding' },
    { bodylist_Name: 'Rocker Panel (Door Sill Panel)' },
    { bodylist_Name: 'Running Board (Foot Step)' },
    { bodylist_Name: 'Tail Door' },
    { bodylist_Name: 'Roof Panel' },
    { bodylist_Name: 'Wind Shield Rear' },
    { bodylist_Name: 'Quarter Panel (Rear)' },
    { bodylist_Name: 'Fuel Lid' },
    { bodylist_Name: 'Rear Panel - Trunk' },
    { bodylist_Name: 'Trunk Lid' },
    { bodylist_Name: 'Trunk Top Mount Lamp' },
    { bodylist_Name: 'Tail Lamp' },
    { bodylist_Name: 'Exhaust Pipe (Muffler)' }
  ];

  damage_Severity_type = [
    { Severity_Name: 'High' },
    { Severity_Name: 'Medium' },
    { Severity_Name: 'Low' },
  ];

  Internal_damage_type = [
    { Severity_Name: 'Yes' },
    { Severity_Name: 'No' }
  ];

  damage_property_type = [
    { Severity_Name: 'Minor Repair', value: 'R1' },
    { Severity_Name: 'Major Repair', value: 'R2' },
    { Severity_Name: 'Replace', value: 'R3' },
  ];

  paint_type = [
    { Severity_Name: 'Metallic', value: 'Metallic' },
    { Severity_Name: 'Non Metallic', value: 'Non Metallic' },
    { Severity_Name: 'Pearl', value: 'Pearl' },
  ];
}
